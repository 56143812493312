<template>
    <layout-full>
        <router-view />
    </layout-full>
</template>
<script>
    export default {
        components: {
            LayoutFull: () => import('@core/layouts/layout-full/LayoutFull.vue'),
        },
    }
</script>